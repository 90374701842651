import axios from '@/plugins/axios';

class TrainingReportApi {

  static getReport(
    page = 1,
    limit = 10,
    userName = '',
    customerMobile = '',
    channelId = NaN,
    storeId = NaN,
    sex = NaN,
    min = NaN,
    max = NaN,
    type = NaN,
  ) {
    return axios({
      url: '/api/train/customerRecord/page',
      data: {
        page,
        limit,
        customer_nick_name: userName,
        customer_mobile: customerMobile,
        channel_id: channelId,
        store_id: storeId,
        sex: sex,
        start_number: min,
        end_number: max, 
        type: type,
      },
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询训练报告列表',
        },
      },
    });
  }

  static getReportList(customerId,startDate,endDate, page = 1, limit = 10
  ) {
    return axios({
      url: `/api/customer/${customerId}/train/record/list`,
      data: { start_date: startDate, end_date: endDate, page, limit},
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '个人训练报告列表',
        },
      },
    });
  }

  static export(data) {
    return axios({
      url: '/api/train/customerRecord/export',
      method: 'post',
      data,
      headers: {
        meta: {
          skipSuccess: true,
          desc: '导出',
        },
      },
    });
  }

  static exportDetail(data) {
    return axios({
      url: '/api/train/recordDetail/batchExport',
      method: 'post',
      data,
      headers: {
        meta: {
          skipSuccess: true,
          desc: '导出',
        },
      },
    });
  }

  static getReportDetail(reportId) {
    return axios({
      url: `api/train/testrecord/${reportId}/detail`,
      method: 'get',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '获取训练报告详情',
        },
      },
    });
  }
}

export default TrainingReportApi;
