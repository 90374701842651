import DataTransferUtil from '@/utils/data-transfer.util';

export const REPORT_COLUMNS = [
  {
    title: '用户姓名',
    key: 'customer_nick_name',
    minWidth: 180,
  },
  {
    title: '手机号',
    key: 'customer_mobile',
    minWidth: 140,
  }, 
  {
    title: '类型',
    key: 'type',
    slot: 'type',
    minWidth: 100,
  }, 
  {
    title: '性别',
    key: 'sex',
    slot: 'sex',
    minWidth: 60,
  }, 
  // {
  //   title: '报告次数',
  //   key: 'number',
  //   slot: 'number',
  //   minWidth: 60,
  // }, 
  {
    title: '最近一次训练时间',
    key: 'create_time',
    minWidth: 140,
  }, 
  {
    title: '操作',
    key: 'action',
    slot: 'action',
    width: 120,
    fixed: 'right',
  },
];

export const REPORT_LIST_COLUMNS = [
  {
    title: '训练时间',
    key: 'start_time',
    minWidth: 140,
  },
  {
    title: '训练方案',
    key: 'train_title',
    minWidth: 130,
  },
  {
    title: '训练地点',
    key: 'store_name',
    minWidth: 130,
    slot: 'store',
  }, 
  {
    title: '操作',
    key: 'action',
    slot: 'action',
    width: 120,
    fixed: 'right',
  },
];


export const REPORT_ACTION = {
  CHECK: 'train:report:person',
};

export const REPORT_ACTION_TEXT = {
  [REPORT_ACTION.CHECK]: '查看报告',
};

export const EXPORT_SEX_OP = {
  0: '女',
  1: '男',
};

export const EXPORT_TYPE_OP = {
  0: '未分类',
  1: '个人用户',
  2: '门店用户',
};

export const EXPORT_SEX_OPTIONS = DataTransferUtil.enumTransfer2Options(EXPORT_SEX_OP);

export const EXPORT_TYPE_OPTIONS = DataTransferUtil.enumTransfer2Options(EXPORT_TYPE_OP);

export const CHART_TYPE = {
  FOCUS_ON: 'red',
  CAN_IMPROVE: 'yellow',
};

export const EYE_TYPE = {
  '20/100': 0,
  '20/80': 1,
  '20/50': 2,
  '20/40': 3,
  '20/30': 2,
}

export const CHART_TYPE_CODE = {
  [CHART_TYPE.FOCUS_ON]: -0.50000000000000,
  [CHART_TYPE.CAN_IMPROVE]: -0.5000000000001,
};

export const CHART_TYPE_TEXT = {
  [CHART_TYPE_CODE[CHART_TYPE.FOCUS_ON]]: 'A',
  [CHART_TYPE_CODE[CHART_TYPE.CAN_IMPROVE]]: 'B',
};

export const CHART_TYPE_COLOR = {
  [CHART_TYPE_CODE[CHART_TYPE.FOCUS_ON]]: '#000',
  [CHART_TYPE_CODE[CHART_TYPE.CAN_IMPROVE]]: '#000',
};