<template>
  <div class="training-report">
    <app-block-flex>
      <app-query-bar
        :queryConfig="queryConfig"
        v-model="queryData"
        @onSearch="fetchReports"
      >
        <template slot="numberRange">
          <app-spacing class="age-range" spacing="sm">
            <div>
              <InputNumber
                :max="150"
                :min="0"
                v-model="queryData.startNumber"
              />
            </div>
            <div>至</div>
            <div>
              <InputNumber :max="150" :min="0" v-model="queryData.endNumber" />
            </div>
          </app-spacing>
        </template>
        <template slot="channelId">
          <app-spacing class="age-range" spacing="sm">
            <div  v-if="this.userType !== 'OUTLET'">
              <el-select
                size="small"
                filterable
                clearable
                :value="queryData.channelId"
                :placeholder="`请选择渠道`"
                @change="(value) => onChange(value)"
              >
                <el-option
                  v-for="option in queryConfigChannel"
                  :value="option.id"
                  :label="option.name"
                  :key="option.id"
                />
              </el-select>
            </div>
          </app-spacing>
        </template>
      </app-query-bar>
    </app-block-flex>
    <app-export-bar @onExport="onExport" @onExportAll="onExportAll" />
    <app-data-table
      :columns="REPORT_COLUMNS"
      :data="reports"
      v-model="queryData"
      @onPageConfigChange="fetchReports"
      :allowed-permissions="Object.values(REPORT_ACTION)"
    >
      <template slot-scope="{ row }" slot="sex">
        {{ EXPORT_SEX_OP[row.sex] }}
      </template>
      <template slot-scope="{ row }" slot="type">
        {{ EXPORT_TYPE_OP[row.type] }}
      </template>
      <template slot-scope="{ row }" slot="number">
        {{ row.number + "份" }}
      </template>
      <app-spacing
        spacing="sm"
        class="table-action"
        slot-scope="{ row }"
        slot="action"
      >
        <Button
          v-if="permissions['train:report:person']"
          type="primary"
          @click="() => openListModal(row.id, true, row.customer_nick_name)"
          class=""
        >
          {{ REPORT_ACTION_TEXT[REPORT_ACTION.CHECK] }}
        </Button>
      </app-spacing>
    </app-data-table>

    <Modal
      class-name="app-modal"
      :width="1000"
      :value="listModalData.visible"
      :title="`${listModalData.userName}的训练报告`"
      :visible="listModalData.visible"
      @on-cancel="openListModal('', false, '')"
    >
      <div slot="footer"></div>
      <app-block-flex>
        <app-query-bar
          :queryConfig="queryConfigs"
          v-model="listModalData"
          @onSearch="reportList"
        />
        <!-- <Button @click="onExportDetail">批量导出</Button> -->
      </app-block-flex>
      <app-data-table
        :columns="REPORT_LIST_COLUMNS"
        :data="listModalData.list"
        v-model="listModalData"
        @onPageConfigChange="reportList"
        :allowed-permissions="Object.values(REPORT_ACTION)"
      >
        <template slot-scope="{ row }" slot="store">
          {{ row.store_name || "自主训练" }}
        </template>
        <app-spacing
          spacing="sm"
          class="table-action"
          slot-scope="{ row }"
          slot="action"
        >
          <Button type="primary" @click="() => onViewReport(row.id)">
            查看报告
          </Button>
        </app-spacing>
      </app-data-table>
    </Modal>
  </div>
</template>

<script>
import { Message } from 'view-design';
import {
  REPORT_COLUMNS,
  REPORT_LIST_COLUMNS,
  EXPORT_SEX_OP,
  EXPORT_TYPE_OP,
  EXPORT_SEX_OPTIONS,
  EXPORT_TYPE_OPTIONS,
  REPORT_ACTION,
  REPORT_ACTION_TEXT,
} from './constants';
import TrainingReportApi from './training-report.api';
import ChannelManageApi from '../../business/channel-manage/channel-manage.api';
import StoreManageApi from '../../business/store-manage/store-manage.api';
import DataTransferUtil from '../../../utils/data-transfer.util';

export default {
  name: 'training-report',
  components: {},
  computed: {
    userType() {
      return this.$store.getters.userType;
    },
    queryConfig() {
      return [
        {
          fieldName: 'userName',
          name: '用户姓名',
        },
        {
          fieldName: 'customerMobile',
          name: '手机号',
        },
        {
          fieldName: 'channelId',
          name: '渠道',
          show: this.userType === 'OUTLET',
          options: this.queryConfigChannelOptions,
          type: 'slot',
        },
        {
          fieldName: 'storeId',
          name: '门店',
          type: 'select',
          options: this.queryConfigStoreOptions,
        },
        {
          fieldName: 'sex',
          name: '性别',
          type: 'select',
          options: EXPORT_SEX_OPTIONS,
        },
        // {
        //   fieldName: "numberRange",
        //   name: "报告次数",
        //   type: "slot",
        // },
        {
          fieldName: 'type',
          name: '类型',
          type: 'select',
          options: EXPORT_TYPE_OPTIONS,
        },
      ];
    },
    queryConfigs() {
      return [
        {
          fieldName: 'dateRange',
          name: '时间',
          type: 'dateRange',
          width: '260',
        },
      ];
    },
  },
  data() {
    return {
      REPORT_COLUMNS,
      REPORT_LIST_COLUMNS,
      EXPORT_SEX_OP,
      EXPORT_TYPE_OP,
      EXPORT_SEX_OPTIONS,
      EXPORT_TYPE_OPTIONS,
      REPORT_ACTION,
      REPORT_ACTION_TEXT,
      queryData: {
        page: 1,
        limit: 10,
        count: 0,
        userName: '',
        customerMobile: '',
        channelId: '',
        storeId: '',
        sex: '',
        startNumber: '',
        endNumber: '',
        type: '',
      },
      listModalData: {
        page: 1,
        limit: 10,
        count: 0,
        visible: false,
        selectId: NaN,
        customerId: '',
        list: [],
        userName: '',
        dateRange: ['', ''],
      },
      reports: [],
      queryConfigChannel: [],
      queryConfigChannelOptions: [],
      queryConfigStoreOptions: [],
    };
  },
  created() {
    this.fetchReports();
    this.fetchQueryStoreOptions('', 0);
    if (this.userType !== 'OUTLET') {
      this.fetchQueryChannelOptions();
    }
  },
  methods: {
    /* 训练报告用户列表 */
    async fetchReports() {
      const {
        page,
        limit,
        userName,
        customerMobile,
        channelId,
        storeId,
        sex,
        startNumber,
        endNumber,
        type,
      } = this.queryData;
      if (startNumber && !Number(startNumber)) {
        Message.error({
          content: '最小报告次数填写错误',
          duration: 2,
          closable: true,
        });
        return;
      }
      if (endNumber && !Number(endNumber)) {
        Message.error({
          content: '最大报告次数填写错误',
          duration: 2,
          closable: true,
        });
        return;
      }
      if (
        Number(startNumber) > Number(endNumber)
        && Number(startNumber)
        && Number(endNumber)
      ) {
        Message.error({
          content: '报告次数范围填写错误',
          duration: 2,
          closable: true,
        });
        return;
      }
      const { count = 0, results = [] } = await TrainingReportApi.getReport(
        page,
        limit,
        userName,
        customerMobile,
        channelId,
        storeId,
        sex,
        startNumber,
        endNumber,
        type,
      );
      this.reports = results;
      this.$set(this.queryData, 'count', count);
    },
    /* 渠道列表 */
    async fetchQueryChannelOptions() {
      const options = await ChannelManageApi.getChannelsByChannelName();
      this.queryConfigChannel = options;
      this.queryConfigChannelOptions = DataTransferUtil.originTransfer2Options(options);
    },
    /* 门店列表 */
    async fetchQueryStoreOptions(name, channelId) {
      const options = await StoreManageApi.getStoresByStoreNameOrChannelId(
        name,
        channelId,
      );
      this.queryConfigStoreOptions = DataTransferUtil.originTransfer2Options(options);
    },
    /* 条件导出 */
    async onExport() {
      const {
        page,
        limit,
        userName,
        customerMobile,
        channelId,
        storeId,
        sex,
        startNumber,
        endNumber,
        type,
      } = this.queryData;
      if (startNumber > endNumber) {
        Message.error({
          content: '报告次数范围填写错误',
          duration: 2,
          closable: true,
        });
        return;
      }
      const key = await TrainingReportApi.export({
        page,
        limit,
        customer_nick_name: userName,
        customer_mobile: customerMobile,
        channel_id: channelId,
        store_id: storeId,
        sex,
        start_number: startNumber,
        end_number: endNumber,
        type,
      });
      await DataTransferUtil.downloadFileBySecretKey(key);
    },
    /* 全部导出 */
    async onExportAll() {
      const key = await TrainingReportApi.export();
      await DataTransferUtil.downloadFileBySecretKey(key);
    },
    /* 报告导出 */
    async onExportDetail() {
      const { list } = this.listModalData;
      const lists = [];
      list.forEach((item) => {
        lists.push(item.id);
      });
      const key = await TrainingReportApi.exportDetail({ id: lists });
      await DataTransferUtil.downloadFileBySecretKey(key);
    },
    /* 报告列表Modal */
    openListModal(id, visible, userName) {
      this.listModalData.customerId = id;
      this.listModalData.visible = visible;
      this.listModalData.userName = userName;
      if (visible) {
        this.reportList();
      } else {
        this.listModalData.page = 1;
      }
    },
    /* 渠道选择 */
    onChange(value) {
      this.queryData.channelId = value;
      this.queryData.storeId = '';
      this.fetchQueryStoreOptions('', value);
    },
    /* 用户报告列表 */
    async reportList() {
      const {
        customerId, dateRange, page, limit,
      } = this.listModalData;
      const [startDate, endDate] = dateRange;
      const { count = 0, results = [] } = await TrainingReportApi.getReportList(
        customerId,
        startDate,
        endDate,
        page,
        limit,
      );
      this.$set(this.listModalData, 'count', count);
      this.listModalData.list = results;
    },
    onViewReport(id) {
      window.open(`${window.location.origin}/#/report?id=${id}`);
    },
  },
};
</script>

<style lang="less" scoped>
.text {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.age-range > div,
.visual-range > div {
  display: flex;
  align-items: center;
  > div {
    margin-right: 4px;
  }
}
</style>
